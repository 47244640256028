import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import TadContent from './TadContent';
import { Container } from 'react-bootstrap';
import TadButton from '../Button/TadButton';

const Wrapper = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: var(--tad-green);
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 1rem 0;
  text-align: left;
  font-weight: bold;
  text-align: left;
  margin-top: 3rem;
`;

const Text = styled.p`
  margin: 1.325rem 0;
  text-align: left;
`;

const TextContent = styled.div`
  text-align: left;
  padding: 1rem 2rem 1rem 2rem;
`;

const Intro = styled.p`
  font-size: 1.125rem;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  width: 80%;
  color: black;
  margin-bottom: 4rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const TadContentPartnerships = () => {
  return (
    <TadContent selectedMenu="Partnerships">
      <Container>
        <Row>
          <Col>
            <Intro className="animate__animated animate__fadeIn">
              At TAD Duty Free, our partnerships are foundational to delivering unparalleled quality and exclusivity to
              our discerning customers. By weaving together the finest offerings from luxury brands and distinguished
              artisans, we curate an exceptional array of products that embody innovation, excellence, and
              sustainability. These collaborations enhance our curated collection, ensuring each visit is a discovery of
              luxury and tradition, without the explicit call for new partners.
            </Intro>
          </Col>
        </Row>
        <Wrapper>
          <Row>
            <Col>
              <TextContent className="animate__animated animate__fadeInUp">
                <Title>ORIEN Bank Tajikistan</Title>
                <Text>
                  Our partnership with Orien Bank has always been excellent, and we are eager to continue this
                  collaboration on the path of progress. Orien Bank is a highly reputable and well-established financial
                  institution with a long-standing tradition and a strong track record of delivering the highest quality
                  services to its customers. As our company expands in Tajikistan and our developments continue to grow,
                  it is crucial to accommodate the volume of our business growth and ensure the sustainability of our
                  projects over the following years. Thus, we are enthusiastic about nurturing this partnership with
                  Orien Bank to reach our shared goals.
                </Text>
                <Actions>
                  <TadButton link="https://www.orienbank.tj/">Visit Orien Bank</TadButton>
                </Actions>
              </TextContent>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <TextContent className="animate__animated animate__fadeInUp">
                <Title>MBHBank Colpack Ballan CSB</Title>
                <Text>
                  MBHBank Colpack Ballan CSB stands as a beacon of excellence, uniting MBHBank's financial prowess,
                  Colpack Ballan's cycling heritage, and CSB's commitment to impactful initiatives. This alliance
                  reflects a shared dedication to fostering talent, supporting sustainability, and championing community
                  engagement. Through strategic collaboration, MBHBank Colpack Ballan CSB has achieved remarkable
                  milestones in both the competitive sports arena and social responsibility projects. Their joint
                  efforts underline a holistic approach to success, balancing athletic achievements with meaningful
                  societal contributions. This partnership exemplifies how synergy between diverse fields can lead to
                  unparalleled accomplishments and lasting positive impact.
                </Text>
                <Actions>
                  <TadButton link="https://www.teammbhbankcolpackballancsb.com/">Visit MBH Bank</TadButton>
                </Actions>
              </TextContent>
              {/* <Row>
              <Col>
                <ButtonWrapper>
                  <Button href="https://www.teamcolpack.it/" type="button" target="_blank">
                    Join the racing journey
                  </Button>
                </ButtonWrapper>
              </Col>
            </Row> */}
            </Col>
            <Col md={5} className="mt-4 d-flex align-items-center justify-content-center">
              <StaticImage
                height={300}
                src="../../images/tad/tad_partnership.png"
                alt="Partnership"
                placeholder="blurred"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextContent className="animate__animated animate__fadeInUp">
                <Title>Weitnauer</Title>
                <Text>
                  Weitnauer stands out as a distinguished entity renowned for its expertise in luxury goods distribution
                  and retail, offering an exquisite selection of high-end products across various categories. With a
                  rich heritage that speaks to its longstanding commitment to excellence, Weitnauer has become
                  synonymous with quality, exclusivity, and unparalleled customer service. This company excels in
                  curating a portfolio of premium brands, ensuring that each product meets the highest standards of
                  craftsmanship and design. Through innovative retail strategies and a deep understanding of market
                  dynamics, Weitnauer continues to shape the landscape of luxury shopping, creating memorable
                  experiences for discerning consumers.
                </Text>
              </TextContent>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <TextContent>
                <Title>Optimum-Filter</Title>
                <Text>
                  Optifilter Research R&D Ltd was founded in 2014. The project company was established around the
                  intellectual property of the novel additives for cigarette filter, which incorporated in cigarette
                  filters provides biological protection against tobacco smoke ingredients.
                  <br />
                  In 2020 the R&D phase ended and from mid-2021, the company - Optifilter International Ltd - engages in
                  manufacturing and trading its own cigarette brand, Optimum-Filter with the special OF Filter.
                </Text>
              </TextContent>
            </Col>
            <Col md={5} className="mt-4 d-flex align-items-center justify-content-center">
              <StaticImage src="../../images/tad/optimum_logo.png" alt="Partnership" placeholder="blurred" />
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </TadContent>
  );
};

export default TadContentPartnerships;
