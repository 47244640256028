import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
`;

const Button = styled.a`
  background-color: var(--tad-green);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background-color: var(--tad-red);
    box-shadow: 0 0 10px 0 var(--tad-red);
    color: white;
  }
`;

const TadButton = ({ children, link }) => {
  return (
    <Wrapper>
      <Button href={link}>{children}</Button>
    </Wrapper>
  );
};

export default TadButton;

TadButton.propTypes = {
  children: PropTypes.string,
  link: PropTypes.string,
};
